<template>
  <div class="credit">
    <Banner>
      <div class="banner-content">
        <MeepIconSimulateurs class="banner-content__icon" />
        <span class="banner-content__text">{{ $t("menu.simulateurs") }}</span>
      </div>
    </Banner>

    <AnnounceModal
      v-model="isAnnounceModalOpen"
      :description="$t('simulateurs.credit.modal-description')"
      @close="closeAnnounceModal"
    />

    <div class="credit-content page-layout">
      <md-card class="meep-form md-card-small">
        <PageHeader
          :has-back="true"
          :hasActions="true"
          :title="$t('simulateurs.credit.title')"
          @back="goBack()"
        >
          <md-button
            class="md-primary header-action header-action-icon md-button-no-margin"
            @click="showAnnounceModal"
          >
            <MeepIconCircleQuestion class="app-icon" />
          </md-button>
        </PageHeader>

        <md-card-content>
          <div class="meep-form-column meep-form-single-column">
            <div class="meep-input">
              <md-field>
                <label>{{ $t("simulateurs.credit.amount") }}</label>
                <md-input
                  v-model.number="$v.form.montant.$model"
                  name="montant"
                />
                <span class="md-suffix">{{ $t("suffix.euro") }}</span>
                <template v-if="$v.form.montant.$dirty">
                  <span v-show="!$v.form.montant.numeric" class="meep-error">
                    {{ $t("meep-errors.number") }}
                  </span>
                  <span v-show="!$v.form.montant.required" class="meep-error">
                    {{ $t("meep-errors.required") }}
                  </span>
                </template>
              </md-field>
            </div>
            <div class="meep-input">
              <md-field>
                <label>{{ $t("simulateurs.credit.taux") }}</label>
                <md-input v-model.number="$v.form.taux.$model" name="taux" />
                <span class="md-suffix">{{ $t("suffix.percent") }}</span>
                <template v-if="$v.form.taux.$dirty">
                  <span v-show="!$v.form.taux.numeric" class="meep-error">
                    {{ $t("meep-errors.number") }}
                  </span>
                  <span v-show="!$v.form.taux.required" class="meep-error">
                    {{ $t("meep-errors.required") }}
                  </span>
                </template>
              </md-field>
            </div>
            <div class="meep-input">
              <md-field>
                <label>{{ $t("simulateurs.credit.duree") }}</label>
                <md-input v-model.number="$v.form.duree.$model" name="duree" />
                <span class="md-suffix">{{ $t("suffix.ans") }}</span>
                <template v-if="$v.form.duree.$dirty">
                  <span v-show="!$v.form.duree.numeric" class="meep-error">
                    {{ $t("meep-errors.number") }}
                  </span>
                  <span v-show="!$v.form.duree.required" class="meep-error">
                    {{ $t("meep-errors.required") }}
                  </span>
                </template>
              </md-field>
            </div>
            <div class="md-layout md-alignment-center-right middle-button">
              <md-button class="md-raised md-primary" @click="calcu">
                {{ $t("simulateurs.cal-button") }}
              </md-button>
            </div>
            <div class="meep-input meep-input-result">
              <md-field>
                <label>{{ $t("simulateurs.credit.monthly-pay") }}</label>
                <p class="result">
                  {{
                    $$filters.formatNumber(results.mensualite) +
                    $t("suffix.euro")
                  }}
                </p>
                <md-input class="md-input-result" disabled />
              </md-field>
            </div>
            <div class="meep-input meep-input-result">
              <md-field>
                <label>{{ $t("simulateurs.credit.cost") }}</label>
                <p class="result">
                  {{ $$filters.formatNumber(results.cout) + $t("suffix.euro") }}
                </p>
                <md-input class="md-input-result" disabled />
              </md-field>
            </div>
          </div>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>

<script>
import Banner from "@/components/Banner";
import MeepIconSimulateurs from "@/components/icons/MeepIconSimulateurs.vue";
import MeepIconCircleQuestion from "@/components/icons/MeepIconCircleQuestion.vue";
import HasBackMixin from "@/mixins/HasBackMixin";
import PageHeader from "@/components/PageHeader";
import { required, numeric } from "vuelidate/lib/validators";
import { credit } from "@/services/util";
import AnnounceModal from "@/components/modal/AnnounceModal.vue";

export default {
  name: "Credit",
  mixins: [HasBackMixin],

  components: {
    Banner,
    MeepIconSimulateurs,
    PageHeader,
    AnnounceModal,
    MeepIconCircleQuestion,
  },

  validations: {
    form: {
      montant: { required, numeric },
      taux: { required, numeric },
      duree: { required, numeric },
    },
  },

  data() {
    return {
      form: {
        montant: null,
        taux: null,
        duree: null,
      },

      results: {
        cout: 0,
        mensualite: 0,
      },
      isAnnounceModalOpen: false,
    };
  },

  methods: {
    calcu() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.results = credit(this.form);
      }
    },

    showAnnounceModal() {
      this.isAnnounceModalOpen = true;
    },

    closeAnnounceModal() {
      this.isAnnounceModalOpen = false;
    },
  },
};
</script>
